/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Button, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Ceník služeb"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--80" name={"informace-2"} layout={"l13"}>
        </Column>


        <Column className="--center pb--80 pt--80" name={"qd4sm7p1be"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Ceník služeb a náplň kurzů"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" layout={"l9"} name={"yialrvqkoyq"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38719/5d7dc8639e8147af939b74ac4915aacf_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":700}} srcSet={"https://cdn.swbpg.com/t/38719/5d7dc8639e8147af939b74ac4915aacf_s=350x_.png 350w, https://cdn.swbpg.com/t/38719/5d7dc8639e8147af939b74ac4915aacf_s=660x_.png 660w, https://cdn.swbpg.com/t/38719/5d7dc8639e8147af939b74ac4915aacf_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/38719/d1d93942707b4038811a277e12d71a3e_s=660x_.png"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":700}} srcSet={"https://cdn.swbpg.com/t/38719/d1d93942707b4038811a277e12d71a3e_s=350x_.png 350w, https://cdn.swbpg.com/t/38719/d1d93942707b4038811a277e12d71a3e_s=660x_.png 660w, https://cdn.swbpg.com/t/38719/d1d93942707b4038811a277e12d71a3e_s=860x_.png 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="bg--bottom --parallax" style={{"backgroundColor":"var(--color-blend--95)","marginTop":0,"paddingBottom":0}} name={"kontakt"} parallax={true}>
          
          <ColumnWrap className="column__flex js-anim  --anim7 --anim-s3 --center el--1" anim={"7"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" style={{"maxWidth":1310}} anim={"7"} animS={"3"}>
              
              <Title className="title-box" content={"Pojďte s námi ovládnout technologie zítřka ještě dnes<br>"}>
              </Title>

              <Button className="btn-box btn-box--shape4" content={"Kontaktujte nás"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"var(--color-supplementary)"}} name={"paticka"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--2" style={{"maxWidth":1450}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">Skolainovace.cz</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":375}} content={"<span style=\"color: var(--white);\">skolainovace.cz, s. r. o.<br>Email: info@skolainovace.cz<br>Telefon: 795 435 923<br>IČ:&nbsp;21803668<br>Malá Štěpánská 1930/7<br>120 00 Praha 2</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"Zadejte text"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":""}} content={"<a href=\"/o_nas\" style=\"color: var(--white);\">O nás</a><a href=\"/blog\" style=\"color: var(--white);\"><br></a><a href=\"/nabidka-kurzu\" style=\"color: var(--white);\">Nabídka kurzů</a><a href=\"/blog\" style=\"color: var(--white);\"><br>Blog<br></a><a href=\"https://www.facebook.com/profile.php?id=61559873844823\" target=\"_blank\" style=\"color: var(--white);\">Facebook</a><a href=\"/blog\" style=\"color: var(--white);\"><br></a><a href=\"/zasady-zpracovani-osobnich-udaju\" style=\"color: var(--white);\">Zásady zpracování osobních údajů</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}